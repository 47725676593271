import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Box, Stack, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import "react-pro-sidebar/dist/css/styles.css";

import ZoomRoom from '@components/ZoomCall/ZoomRoom';
import LeaveMeeting from '@components/ZoomCall/LeaveMeeting';
import EndMeeting from '@components/ZoomCall/EndMeeting';
import { addOrderToMeeting as addOrderToMeetingAPI } from '@store/slices/meeting.slice';
import { getMeetingDetailById, addOrderToMeeting, statusMeeting } from "@services/zoom.service";
import "@assets/pages/ZoomCall.css";

const StyledLaptopZoomRoomWrapper = styled(Box)({
  '& #meetingSDKElement': {
    width: '50%',
  },
  '& .video-avatar__avatar-name': {
    overflow: 'visible'
  }
});

export default function Other() {
  const [sidebarState, setSidebarState] = useState(false);
  const [meetingDetail, setMeetingDetail] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useTheme();
  // const ecwid = useSelector(state => state.ecwid);
  // ecwid.Cart.clear();

  window.addEventListener("resize", function(event) {
    let widthS = window.innerWidth;
    let endBtn = document.getElementById('divLeaveMeeting');
    if(widthS < 900 && endBtn.classList.contains("divLeaveMeetingView")){
      endBtn.style.display = 'flex';
    }else{
      endBtn.style.display = 'none';
    }
    if(endBtn.classList.contains("divLeaveMeetingView")){
      let shm = document.getElementsByClassName('sidebarShowMenu')[0];
      shm.style.height = '71vh';
      let shmu = document.getElementsByClassName('sidebarShowMenuURL')[0];
      shmu.style.height = '71vh';
    }
  });

  useEffect(() => {
    getMeetingDetailById({ zoom_id: id })
      .then(response => {
        setMeetingDetail(response);
        console.log("Respuesta: ", response);
        console.log("Zoom ID: ", response.zoom_id);
        console.log("***** ID: ", id);
        // statusM(response.zoom_id);
        console.log("SharedArrayBuffer: ", typeof SharedArrayBuffer === 'function');

        let url_store = response.url_store;
        
        if(url_store != '' && url_store != null){
          let divStoreDefault = document.getElementById('storeDefault');
          divStoreDefault.style.display = "none";

          const iframeStore = document.createElement("iframe");
          iframeStore.id = 'iFrameURL';
          iframeStore.src = url_store;
          iframeStore.style.border = "none";
          iframeStore.style.width = '100%';
          iframeStore.style.height = '100%';
          iframeStore.name = "url_store";
          iframeStore.title = "Store ID";
          iframeStore.allowFullscreen = true;
          
          document.getElementById('storeURL').appendChild(iframeStore);
        }else{
          let divStoreDefault = document.getElementById('storeURL');
          divStoreDefault.style.display = "none";
          
          let id_store = '75812399';
          const script = document.createElement('script');
          script.crossOrigin = "anonymous";
          script.charset="utf-8";
          script.src = `https://app.workinglive.biz/script.js?${id_store}&data_platform=code&data_date=2022-06-03`;
          
          
          script.onload = () => {
            // window.xProductBrowser("categoriesPerRow=4","categoryView=grid","searchView=list","id=my-store-75812399");
            window.xProductBrowser(`id=my-store-${id_store}`, "defaultCategoryId=0", "views=grid(10000,1)","categoryView=grid","searchView=list");
            window.Ecwid.init();
            // setTimeout(() => {
            //   window.Ecwid.Cart.clear();
            // }, 500);
            
            window.Ecwid.OnPageLoaded.add(function (page) {
              let i = 0;
              setTimeout(() => {
                // console.log('PAGE: ', page);
                let loadedProducts = document.getElementsByClassName('grid-product');
                // console.log('PRODUCTOS: ',loadedProducts, loadedProducts.length);
                while (1) {
                  if (i >= loadedProducts.length){
                    break;
                  }
                  const thisProductId = loadedProducts[i].classList[1].replace("grid-product--id-", "");
                  if (!response.product_ids.includes(String(thisProductId))) {
                    loadedProducts[i] && loadedProducts[i]?.remove();
                    i --;
                  }
                  i ++;
                }
              }, 500);
            });
  
            window.Ecwid.OnOrderPlaced.add(function (order) {
              addOrderToMeeting({ meeting_id: response.id, order_id: order.orderNumber })
                .then(response => {
                  dispatch(addOrderToMeetingAPI({ order: response }));
                })
                .catch(err => {
                  console.log(err);
                  enqueueSnackbar("Internal Server Error!", { variant: 'error' });
                });
            });
          }
          document.getElementById('ecwid-script-wrapper').appendChild(script);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);
  
  return (
    <div className="Sidebar-Container">
      <div className="main">
        <div className="Banner">
          <h3 className="BannerText">
            WorkingLive
          </h3>
        </div>
        <div className="ZoomRoomContainer">
          <div className="ZoomRoomContent">
            {
              meetingDetail &&  
                <StyledLaptopZoomRoomWrapper>
                  <ZoomRoom meetingConfig={ JSON.parse(meetingDetail.zoom_info) } hostUserID={ meetingDetail.host_user } />
                  <LeaveMeeting></LeaveMeeting>
                </StyledLaptopZoomRoomWrapper>
            }
          </div>
          <div className="sidebarShowMenu" id="storeDefault">
            <div className="header-buttons">
              <div>
                <div className="ec-cart-widget"></div>
                Checkout
              </div>
            </div>
            <div id="my-store-75812399"></div>
            <div id="ecwid-script-wrapper"></div>
          </div>
          <div className="sidebarShowMenuURL" id="storeURL"></div>
        </div>
      </div>
    </div>
  );
}
