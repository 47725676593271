import { Grid, Box, Typography, Link, Button, Stack, useMediaQuery } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import WKPanel from "@components/Base/WKPanel";
import WKButtonLink from "@components/Base/WKButtonLink";
import { toWorkingLiveDate, toWorkingLiveTime, toFullZoomCallUrl } from "@utils/transformers";

const NewMeetingPanel = (props) => {
    const { meeting, onAddAffiliate, onDelete } = props;
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
        <WKPanel sx={{ width: '100%' }}>
            <Grid container>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #EEEEEE', pb: 3, width: '100%' }}>
                    {
                        isOnMobile ?
                            <Stack sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2 }}>{ meeting.topic }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888' }}>Meeting ID: { meeting.zoom_id }</Typography>
                                </Box>
                                <Stack sx={{ mt: 2, width: '100%' }}>
                                    <WKButtonLink onClick={ onDelete }><DeleteOutlineIcon />&ensp;Delete</WKButtonLink>
                                    <WKButtonLink sx={{ mt: 2 }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#000' }}>
                                            <g>
                                            <path d="M7.33301 2.6665H2.66634C2.31272 2.6665 1.97358 2.80698 1.72353 3.05703C1.47348 3.30708 1.33301 3.64622 1.33301 3.99984V13.3332C1.33301 13.6868 1.47348 14.0259 1.72353 14.276C1.97358 14.526 2.31272 14.6665 2.66634 14.6665H11.9997C12.3533 14.6665 12.6924 14.526 12.9425 14.276C13.1925 14.0259 13.333 13.6868 13.333 13.3332V8.6665" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                        </svg>
                                        &ensp;Edit
                                    </WKButtonLink>
                                    <Button variant="contained"
                                            sx={{ mt: 2, border: '1px solid #000', backgroundColor: '#fff', color: '#000', height: '40px', width: '100%',
                                                '&:hover, &:hover>.MuiSvgIcon-root': {
                                                    color: '#fff'
                                                }
                                            }}
                                            component={ Link } target="_blank" rel="noopener noreferrer" href={ toFullZoomCallUrl(meeting.zoom_id) }>
                                        <PlayCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />&ensp;Go To Meeting
                                    </Button>
                                </Stack>
                            </Stack>
                        :
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2 }}>{ meeting.topic }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888' }}>Meeting ID: { meeting.zoom_id }</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <WKButtonLink onClick={ onDelete }><DeleteOutlineIcon />&ensp;Delete</WKButtonLink>
                                    <WKButtonLink sx={{ ml: 3 }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#000' }}>
                                            <g>
                                            <path d="M7.33301 2.6665H2.66634C2.31272 2.6665 1.97358 2.80698 1.72353 3.05703C1.47348 3.30708 1.33301 3.64622 1.33301 3.99984V13.3332C1.33301 13.6868 1.47348 14.0259 1.72353 14.276C1.97358 14.526 2.31272 14.6665 2.66634 14.6665H11.9997C12.3533 14.6665 12.6924 14.526 12.9425 14.276C13.1925 14.0259 13.333 13.6868 13.333 13.3332V8.6665" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                        </svg>
                                        &ensp;Edit
                                    </WKButtonLink>
                                    <Button variant="contained"
                                            sx={{ ml: 3, border: '1px solid #000', backgroundColor: '#fff', color: '#000', height: '40px',
                                                '&:hover, &:hover>.MuiSvgIcon-root': {
                                                    color: '#fff'
                                                }
                                            }}
                                            component={ Link } target="_blank" rel="noopener noreferrer" href={ toFullZoomCallUrl(meeting.zoom_id) }>
                                        <PlayCircleOutlineIcon sx={{ color: theme.palette.primary.main }} />&ensp;Go To Meeting
                                    </Button>
                                </Box>
                            </>
                    }
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, width: '100%' }}>
                    {
                        isOnMobile ?
                            <Stack sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2, letterSpacing: '2px' }}>{ toWorkingLiveDate(meeting.scheduled_datetime) }</Typography>
                                    <Typography variant="h4" sx={{ letterSpacing: '2px' }}>{ toWorkingLiveTime(meeting.scheduled_datetime) }</Typography>
                                </Box>
                                <Stack sx={{ width: '100%', mt: 2 }}>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, width: '100%' }}>
                                        <ShareIcon sx={{ color: '#000' }} />&ensp;Share
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, mt: 2, width: '100%' }} onClick={ onAddAffiliate }>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6457 9.70271L6.29778 5.35481C6.53237 4.9578 6.66699 4.4947 6.66699 4.00016C6.66699 2.5274 5.47308 1.3335 4.00033 1.3335C2.52757 1.3335 1.33366 2.5274 1.33366 4.00016C1.33366 5.47292 2.52757 6.66683 4.00033 6.66683C4.49486 6.66683 4.95796 6.53221 5.35497 6.29762L9.70287 10.6455C9.46828 11.0425 9.33366 11.5056 9.33366 12.0002C9.33366 13.4729 10.5276 14.6668 12.0003 14.6668C13.4731 14.6668 14.667 13.4729 14.667 12.0002C14.667 10.5274 13.4731 9.3335 12.0003 9.3335C11.5058 9.3335 11.0427 9.46812 10.6457 9.70271ZM12.0003 13.3335C11.2639 13.3335 10.667 12.7365 10.667 12.0002C10.667 11.2638 11.2639 10.6668 12.0003 10.6668C12.7367 10.6668 13.3337 11.2638 13.3337 12.0002C13.3337 12.7365 12.7367 13.3335 12.0003 13.3335ZM2.66699 4.00016C2.66699 4.73654 3.26395 5.3335 4.00033 5.3335C4.7367 5.3335 5.33366 4.73654 5.33366 4.00016C5.33366 3.26378 4.7367 2.66683 4.00033 2.66683C3.26395 2.66683 2.66699 3.26378 2.66699 4.00016Z" />
                                            </g>
                                        </svg>
                                        &ensp;Add Affiliates
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, mt: 2, width: '100%' }}>
                                        <ContentCopyIcon sx={{ color: '#000' }} />&ensp;Copy Invitation
                                    </Button>
                                </Stack>
                            </Stack>
                        :
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2, letterSpacing: '2px' }}>{ toWorkingLiveDate(meeting.scheduled_datetime) }</Typography>
                                    <Typography variant="h4" sx={{ letterSpacing: '2px' }}>{ toWorkingLiveTime(meeting.scheduled_datetime) }</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton }}>
                                        <ShareIcon sx={{ color: '#000' }} />&ensp;Share
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, ml: 2 }} onClick={ onAddAffiliate }>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6457 9.70271L6.29778 5.35481C6.53237 4.9578 6.66699 4.4947 6.66699 4.00016C6.66699 2.5274 5.47308 1.3335 4.00033 1.3335C2.52757 1.3335 1.33366 2.5274 1.33366 4.00016C1.33366 5.47292 2.52757 6.66683 4.00033 6.66683C4.49486 6.66683 4.95796 6.53221 5.35497 6.29762L9.70287 10.6455C9.46828 11.0425 9.33366 11.5056 9.33366 12.0002C9.33366 13.4729 10.5276 14.6668 12.0003 14.6668C13.4731 14.6668 14.667 13.4729 14.667 12.0002C14.667 10.5274 13.4731 9.3335 12.0003 9.3335C11.5058 9.3335 11.0427 9.46812 10.6457 9.70271ZM12.0003 13.3335C11.2639 13.3335 10.667 12.7365 10.667 12.0002C10.667 11.2638 11.2639 10.6668 12.0003 10.6668C12.7367 10.6668 13.3337 11.2638 13.3337 12.0002C13.3337 12.7365 12.7367 13.3335 12.0003 13.3335ZM2.66699 4.00016C2.66699 4.73654 3.26395 5.3335 4.00033 5.3335C4.7367 5.3335 5.33366 4.73654 5.33366 4.00016C5.33366 3.26378 4.7367 2.66683 4.00033 2.66683C3.26395 2.66683 2.66699 3.26378 2.66699 4.00016Z" />
                                            </g>
                                        </svg>
                                        &ensp;Add Affiliates
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, ml: 2 }}>
                                        <ContentCopyIcon sx={{ color: '#000' }} />&ensp;Copy Invitation
                                    </Button>
                                </Box>
                            </>
                    }
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
                    {
                        meeting.description
                            ?
                                <Typography variant="body1" sx={{ background: 'linear-gradient(#000, #fff)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                    { meeting.description }
                                </Typography>
                            :
                            <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>No description.</Typography>
                    }
                    {/**
                    <Typography variant="h5" color="primary" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3, cursor: 'pointer' }}><KeyboardArrowUpIcon />&ensp;Hide Description</Typography>
                    */}
                </Grid>
            </Grid>
        </WKPanel>
    );
}

export default NewMeetingPanel;