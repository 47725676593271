import { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions , Typography, IconButton, Box, Stepper, Step, StepLabel, Grid, InputLabel, Link,
    FormControl, Backdrop, Modal, Fade, Stack, useMediaQuery
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import MonitorIcon from '@mui/icons-material/Monitor';
import { useSnackbar } from 'notistack';
import crypto from 'crypto';

import WKInput from "@components/Base/WKInput";
import WKTextField from "@components/Base/WKTextField";
import ProductPanel from "./ProductPanel";
import { toWorkingLiveDate, toWorkingLiveTime, toFullZoomCallUrl, toPostgreDateTimeFormat, toZoomDateTimeFormat, toDateTime } from "@utils/transformers";

import { createRoom, createMeeting } from "@services/zoom.service";
import { addNewMeeting } from "@store/slices/meeting.slice";

import "@assets/pages/style.css";

const steps = ['Schedule', 'Products', 'Review'];

const NewMeeting = props => {
    const [roomurl, setRoomUrl] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [topic, setTopic] = useState('');
    const [meetingDate, setMeetingDate] = useState(null);
    const [meetingTime, setMeetingTime] = useState(null);
    const [description, setDescription] = useState('');
    const [searchText, setSearchText] = useState('');
    const [urlStore, setUrlStore] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [topicEditMode, setTopicEditMode] = useState(false);
    const [isUrlCopied, setIsUrlCopied] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const ecwid = useSelector(state => state.ecwid);
    const auth = useSelector(state => state.auth);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { open } = props;

    setTimeout(() => {
        var today = new Date();
        var date_now = today.toLocaleDateString('en-US');
        // var time_now = today.toLocaleTimeString('en-US');
        var time_now = today.toISOString('en-US');
        console.log('NOW: ', today.toISOString('en-US'));
        // var moment = require('moment');
        // var now = moment().format("HH:mm");
        // setMeetingDate(date_now);
        // setMeetingTime(time_now);
    }, 1000);
    // date_now = null;
    // time_now = null;

    // console.log('URL Store:', urlStore);

    const init = () => {
        var today = new Date();
        var date_now = today.toLocaleDateString('en-US');
        var time_now = today.toISOString('en-US');
        // console.log('NOW: ', time_now);
        date_now = null;
        time_now = null;
        setRoomUrl("");
        setActiveStep(0);
        setTopic('');
        setMeetingDate(date_now);
        setMeetingTime(time_now);
        setDescription('');
        setSearchText('');
        setUrlStore('');
        setSelectedProducts([]);
        setTopicEditMode(false);
        setIsUrlCopied(false);
        setOpenConfirmDialog(false);
    }

    const validateMeetingInfo = () => {
        if (!topic) {
            enqueueSnackbar("Please type meeting topic!", { variant: 'error' });
            return false;
        } else if (!meetingDate) {
            enqueueSnackbar("Please select meeting date!", { variant: 'error' });
            return false;
        } else if (!meetingTime) {
            enqueueSnackbar("Please select meeting time!", { variant: 'error' });
            return false;
        }
        return true;
    }

    const validateSelectProducts = () => {
        if (!selectedProducts.length && urlStore == '') {
            enqueueSnackbar("Please select at least one product or enter a URL!", { variant: 'error' });
            return false;
        }
        return true;
    }

    const handleNext = () => {
        if (activeStep == 0 && !validateMeetingInfo()) return;
        else if (activeStep == 1 && !validateSelectProducts()) return;
        
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const onClipboard = () => {
        navigator.clipboard.writeText(roomurl).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            setIsUrlCopied(true);
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
            setIsUrlCopied(false);
        });
    }
    
    const generateSignature = async (props) => {
        const apiKey = props.apiKey;
        const apiSecret = props.apiSecret;
        const meetingNumber = props.meetingNumber;
        const role = props.role;
        // Prevent time sync issue between client signature generation and zoom 
        const timestamp = new Date().getTime() - 30000
        const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
        const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64')
        const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')
        if ('success' in props && props.success){
          props.success(signature);
        }
        
        return signature
    }

    const scheduleMeeting = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        
        let response = await createRoom({ topic, start_time: toZoomDateTimeFormat(toDateTime(meetingDate, meetingTime)) });
        
        var meetingConfig = {
            mn: response.id,
            name: auth.account.username,
            pwd: "",
            role: 1,
            email: "marykaytest@workinglive.us",
            lang: "en-US",
            signature: "",
            china: "Global",
        };

        var signature = generateSignature({
            meetingNumber: meetingConfig.mn,
            apiKey: "4pD3SgwASqWrojOZ3TXRoA",
            apiSecret: "AkAGuUHoxnXtNlMbdB90VxFYjQaGWGaLzzxw",
            role: meetingConfig.role,
            success: async function (res) {
                meetingConfig.signature = res;
                meetingConfig.apiKey = "4pD3SgwASqWrojOZ3TXRoA";

                console.log("meetingConfig: ", meetingConfig);
                
                const scheduled_datetime = toDateTime(meetingDate, meetingTime);

                createMeeting({
                    topic,
                    scheduled_datetime: toPostgreDateTimeFormat(scheduled_datetime),
                    description,
                    zoom_id: meetingConfig.mn,
                    zoom_info: JSON.stringify(meetingConfig),
                    product_ids: selectedProducts.join(','),
                    host_user: auth.account.user_id,
                    url_store: urlStore
                })
                .then(newMeeting => {
                    newMeeting.zoom_info = JSON.parse(newMeeting.zoom_info);
                    setRoomUrl(toFullZoomCallUrl(newMeeting.zoom_id));
                    dispatch(addNewMeeting({ meeting: newMeeting }));
                })
                .catch(err => {});
            },
            error: function (err) {
                console.log(err);
                enqueueSnackbar("Cannot create zoom link!", { variant: 'error' });
            }
        });
    }

    const handleMeetingDate = _date => {
        const now = new Date(Date.now());
        if (!(_date.getFullYear() == now.getFullYear() && _date.getMonth() == now.getMonth() && _date.getDate() == now.getDate()) && (_date < now)) {
            enqueueSnackbar("You cannot schedule meeting on this date!", { variant: 'error' });
            setMeetingDate(null);
            setMeetingTime(null);
            return;
        }

        setMeetingDate(_date);
    }

    const handleMeetingTime = _time => {
        if (!meetingDate) {
            enqueueSnackbar("Select a date!", { variant: 'error' });
            setMeetingTime(null);
            return;
        } else {
            const now = Date.now();
            const meetingDateTime = toDateTime(meetingDate, _time);
            setTimeout(() => {
                console.log('DATE: ', meetingDate);
                console.log('TIME: ', _time);
                console.log('DATE TIME: ', meetingDateTime);
                console.log('NOW: ', now);
            }, 1000);
            if (meetingDateTime < now) {
                enqueueSnackbar("You cannot schedule meeting on this date!", { variant: 'error' });
                setMeetingTime(null);
                return;
            }
        }
        setMeetingTime(_time);
    }

    const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);

    const handleCloseConfirmDialog = (_, reason) => reason !== "backdropClick" && setOpenConfirmDialog(false);

    const handleCloseModal = () => {
        init();
        handleCloseConfirmDialog();
        props.onClose();
    }

    const handleCheckProduct = itemId => e => {
        let currentSelectedProducts = [ ...selectedProducts ];

        if (e.target.checked)
            !currentSelectedProducts.includes(itemId) && currentSelectedProducts.push(itemId);
        else {
            const index = currentSelectedProducts.indexOf(itemId);
            index > -1 && currentSelectedProducts.splice(index, 1);
        }

        setSelectedProducts(currentSelectedProducts);
    }

    const filteredProducts = !searchText ? ecwid.products : ecwid.products.filter(product => product?.name.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ zIndex: 'dialog' }}>
            <Dialog fullWidth={true} maxWidth='sm' open={open} keepMounted aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ zIndex: 'dialog' }}>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={handleOpenConfirmDialog}><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="modal_title" sx={{ textTransform: 'uppercase', textAlign: 'center', px: 5 }}>
                        {
                            activeStep < steps.length ? "New Meeting" : "Invite others to join your meeting by sharing the link below!"
                        }
                    </Typography>
                    <Typography variant="modal_title" sx={{ textTransform: 'uppercase', textAlign: 'center', px: 5 }}>
                        <br></br>
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {
                            steps.map(label =>
                                <Step key={label}>
                                    <StepLabel sx={{ '& .MuiStepLabel-label': { ...theme.typography.h5, color: 'black' } }}>{label}</StepLabel>
                                </Step>
                            )
                        }
                    </Stepper>
                </DialogTitle>
                <DialogContent sx={{ width: '100%', p: 5 }} elevation={1}>
                    {
                        activeStep === steps.length
                            ?
                            <Fragment>
                                <Grid container>
                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%' }}>
                                        <Typography variant="h3" align="center" sx={{ display: 'flex' }}>{ topic }</Typography>
                                    </Grid>
                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            isOnMobile ?
                                                <Stack sx={{ width: '100%' }}>
                                                    <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}><CalendarTodayIcon sx={{ mr: '7px' }} />
                                                        {
                                                            toWorkingLiveDate(meetingDate)
                                                        }
                                                    </Typography>
                                                    <Typography variant="subtitle" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}><QueryBuilderIcon sx={{ mr: '7px' }} />
                                                        {
                                                            toWorkingLiveTime(meetingTime)
                                                        }
                                                    </Typography>
                                                </Stack>
                                            :
                                                <>
                                                    <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}><CalendarTodayIcon sx={{ mr: '7px' }} />
                                                        {
                                                            toWorkingLiveDate(meetingDate)
                                                        }
                                                    </Typography>
                                                    <Typography variant="subtitle" sx={{ ml: 4, display: 'flex', alignItems: 'center' }}><QueryBuilderIcon sx={{ mr: '7px' }} />
                                                        {
                                                            toWorkingLiveTime(meetingTime)
                                                        }
                                                    </Typography>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item md={12} xs={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="body1" align="center">
                                            { description }
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12} sx={{ mt: 6, px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', m: 0, width: '100%', px: 1, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="subtitle">{ roomurl ? roomurl : "..." }</Typography>
                                            <IconButton onClick={onClipboard} sx={{ p: 0, width: '20px', height: '20px', cursor : 'pointer' }}>
                                                {
                                                    !isUrlCopied
                                                        ? <ContentCopyIcon />
                                                        : <CheckIcon color="success" />
                                                }
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={12} sx={{ mt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="body1">Add to my calendar:</Typography>
                                        {
                                            isOnMobile ?
                                                <Stack sx={{ mt: 2 }}>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Google Calendar</Typography>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase', mt: 2 }}>Outlook</Typography>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase', mt: 2 }}>Ical</Typography>        
                                                </Stack>
                                            :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Google Calendar</Typography>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase', ml: 4 }}>Outlook</Typography>
                                                    <Typography variant="h5" sx={{ textTransform: 'uppercase', ml: 4 }}>Ical</Typography>
                                                </Box>
                                        }
                                    </Grid>
                                    <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, width: '100%' }}>
                                        {
                                            isOnMobile ?
                                                <Stack sx={{ width: '100%' }}>
                                                    <Button variant="contained"
                                                            sx={{
                                                                px: 3, py: 2, width: '100%',
                                                                border: '1px solid #000',
                                                                backgroundColor: '#fff',
                                                                color: '#000', height: '40px',
                                                                '&:hover, &:hover>.MuiSvgIcon-root': {
                                                                    color: '#fff'
                                                                }
                                                            }}>
                                                        <ShareIcon sx={{ mr: '5px' }} />Share
                                                    </Button>
                                                    <Button variant="contained" color="primary" sx={{ mt: 2, px: 3, py: 2, width: '100%', '&>.MuiSvgIcon-root': { color: '#fff', opacity: 1} }}
                                                            component={ Link } target="_blank" rel="noopener noreferrer" href={ roomurl ? roomurl : "#" }>
                                                        <MonitorIcon sx={{ mr: '5px', fill: '#fff' }} />Invite
                                                    </Button>
                                                </Stack>
                                            :
                                                <>
                                                    <Button variant="contained"
                                                            sx={{
                                                                px: 3, py: 2,
                                                                border: '1px solid #000',
                                                                backgroundColor: '#fff',
                                                                color: '#000', height: '40px',
                                                                '&:hover, &:hover>.MuiSvgIcon-root': {
                                                                    color: '#fff'
                                                                }
                                                            }}>
                                                        <ShareIcon sx={{ mr: '5px' }} />Share
                                                    </Button>
                                                    <Button variant="contained" color="primary" sx={{ ml: 3, px: 3, py: 2, '&>.MuiSvgIcon-root': { color: '#fff', opacity: 1} }}
                                                            component={ Link } target="_blank" rel="noopener noreferrer" href={ roomurl ? roomurl : "#" }>
                                                        <MonitorIcon sx={{ mr: '5px', fill: '#fff' }} />Invite
                                                    </Button>
                                                </>
                                        }
                                    </Grid>
                                </Grid>
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    (() => {
                                        if (activeStep === 0) {
                                            return (
                                                <Grid container noValidate>
                                                    <Grid item md={12} sx={{ width: '100%' }}>
                                                        <FormControl variant="standard" size="small" fullWidth={true}>
                                                            <InputLabel shrink htmlFor="meeting-topic">
                                                                Topic
                                                            </InputLabel>
                                                            <WKInput id="meeting-topic" size="small"
                                                                value={ topic }
                                                                onChange={ e => setTopic(e.target.value) }
                                                                placeholder="Topic"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3, width: '100%' }}>
                                                        <Grid container columnSpacing={2}>
                                                            <Grid item md={6} xs={12}>
                                                                <InputLabel shrink>
                                                                    Date
                                                                </InputLabel>
                                                                <DatePicker
                                                                    value={meetingDate}
                                                                    onChange={ handleMeetingDate }
                                                                    renderInput={(params) => <WKTextField {...params} size='small' sx={{ width: '100%' }} />}
                                                                />
                                                            </Grid>
                                                            <Grid item md={6} xs={12} sx={{ mt: isOnMobile ? 2: 0 }}>
                                                                <InputLabel shrink>
                                                                    Time
                                                                </InputLabel>
                                                                <TimePicker
                                                                    value={meetingTime}
                                                                    onChange={ handleMeetingTime }
                                                                    renderInput={(params) => <WKTextField {...params} size='small' sx={{ width: '100%' }} />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3, width: '100%' }}>
                                                        <FormControl variant="standard" fullWidth={true}>
                                                            <InputLabel shrink>
                                                                Description (optional)
                                                            </InputLabel>
                                                            <WKTextField multiline rows={5} sx={{ width: '100%', mt: 3 }}
                                                                value={ description }
                                                                onChange={ e => setDescription(e.target.value) }
                                                                placeholder="Description"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (activeStep === 1) {
                                            return (
                                                // Search Products
                                                <Grid container>
                                                    <Grid item md={12} sx={{ width: '100%' }}>
                                                        <FormControl variant="standard" size="small" fullWidth={true} sx={{ width: '100%' }}>
                                                            <InputLabel shrink htmlFor="url-store">
                                                                URL Store
                                                            </InputLabel>
                                                            <WKInput id="url-store" size="small"
                                                                value={ urlStore } onChange={ e => setUrlStore(e.target.value) } placeholder="URL Store" />
                                                        </FormControl>
                                                        <FormControl variant="standard" size="small" fullWidth={true} sx={{ width: '100%' }}>
                                                            <InputLabel shrink htmlFor="product-search">
                                                                Search
                                                            </InputLabel>
                                                            <WKInput id="product-search" size="small"
                                                                value={ searchText } onChange={ e => setSearchText(e.target.value) } placeholder="Search a Product" />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3 }}>
                                                        <div style={{ height: '400px', overflowY: 'scroll' }}>
                                                            {
                                                                filteredProducts?.map(item =>
                                                                    <ProductPanel
                                                                        key={ item.id }
                                                                        name={ item.name }
                                                                        imageUrl={ item.smallThumbnailUrl }
                                                                        price={ item.price }
                                                                        edit={ true }
                                                                        checked={ selectedProducts.includes(item.id) }
                                                                        onChange={ handleCheckProduct(item.id) }
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (activeStep == 2) {
                                            return (
                                                <Grid container>
                                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%' }}>
                                                        {
                                                            topicEditMode === false
                                                                ?
                                                                    <>
                                                                        <Typography variant="h3" align="center" sx={{ display: 'flex' }}>{ topic }</Typography>
                                                                        <IconButton sx={{ position: 'absolute', right: 0 }} onClick={ () => setTopicEditMode(true) }><EditIcon /></IconButton>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <WKInput size="small" sx={{ width: '100%' }} value={ topic } onChange={ e => setTopic(e.target.value) }/>
                                                                        <IconButton sx={{ position: 'absolute', right: 0 }} onClick={ () => setTopicEditMode(false) }><CheckIcon /></IconButton>
                                                                    </>
                                                        }
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                        {
                                                            isOnMobile ?
                                                                <Stack sx={{ width: '100%' }}>
                                                                    <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}><CalendarTodayIcon sx={{ mr: '7px' }} />
                                                                        {
                                                                            toWorkingLiveDate(meetingDate)
                                                                        }
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}><QueryBuilderIcon sx={{ mr: '7px' }} />
                                                                        {
                                                                            toWorkingLiveTime(meetingTime)
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            :
                                                                <>
                                                                    <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}><CalendarTodayIcon sx={{ mr: '7px' }} />
                                                                        {
                                                                            toWorkingLiveDate(meetingDate)
                                                                        }
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{ ml: 4, display: 'flex', alignItems: 'center' }}><QueryBuilderIcon sx={{ mr: '7px' }} />
                                                                        {
                                                                            toWorkingLiveTime(meetingTime)
                                                                        }
                                                                    </Typography>
                                                                </>
                                                        }
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography variant="body1" align="center"> { description } </Typography>
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'  }}>
                                                        <Typography variant="subtitle" sx={{ ml: 4, display: 'flex', alignItems: 'center' }}> <ShareIcon sx={{ mr: '7px' }} /> { urlStore } </Typography>
                                                    </Grid>
                                                    <Grid item md={12} sx={{ mt: 3 }}>
                                                        {/* if(!selectedProducts.length) {} */}
                                                        {/* <div style={{ height: '300px', overflowY: 'scroll' }} > */}
                                                            {
                                                                selectedProducts?.map(itemId =>
                                                                    {
                                                                        const selectedProduct = ecwid.products.find(item => item.id == itemId);

                                                                        return (
                                                                            <ProductPanel
                                                                                edit={ false }
                                                                                key={ selectedProduct.id }
                                                                                name={ selectedProduct.name }
                                                                                imageUrl={ selectedProduct.imageUrl }
                                                                                price={ selectedProduct.price }
                                                                            />
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        {/* </div> */}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        return (<></>);
                                    })()
                                }
                            </Fragment>
                    }
                </DialogContent>
                    {
                        (() => {
                            if (activeStep === 0) {
                                return (
                                    <Grid container noValidate>
                                        <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, width: '100%' }}>
                                            <Button variant="contained" color="primary" onClick={handleNext} sx={Object.assign({ px: 3, py: 2 } )}>Select Products<KeyboardArrowRightIcon /></Button>
                                        </Grid>
                                    </Grid>
                                );
                            } else if (activeStep === 1) {
                                return (
                                    // Search Products
                                    <Grid container>
                                        <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, width: '100%' }}>
                                            <Button variant="contained" sx={{ px: 3, py: 2, border: '1px solid #000', backgroundColor: '#fff', color: '#000', height: '40px', '&:hover, &:hover>.MuiSvgIcon-root': { color: '#fff' } }} onClick={handleBack}><KeyboardArrowLeftIcon />Back</Button>
                                            <Button variant="contained" color="primary" onClick={handleNext} sx={{ ml: 3, px: 3, py: 2 }}>Review<KeyboardArrowRightIcon /></Button>
                                        </Grid>
                                    </Grid>
                                );
                            } else if (activeStep == 2) {
                                return (
                                    <Grid container>
                                        <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, width: '100%' }}>
                                            <Button variant="contained" sx={{ px: 3, py: 2, border: '1px solid #000', backgroundColor: '#fff', color: '#000', height: '40px', '&:hover, &:hover>.MuiSvgIcon-root': { color: '#fff' } }} onClick={handleBack}> <KeyboardArrowLeftIcon />Back</Button>
                                            <Button variant="contained" color="primary" onClick={scheduleMeeting} sx={{ ml: 3, px: 3, py: 2 }}>Schedule Meeting</Button>
                                        </Grid>
                                    </Grid>
                                );
                            }
                            return (<></>);
                        })()
                    }
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
                open={openConfirmDialog} onClose={handleCloseConfirmDialog} closeAfterTransition
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} sx={{ zIndex: 'tooltip' }}>
                <Fade in={openConfirmDialog}>
                    <Box sx={ theme.custom.WKDialog }>
                        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                            <IconButton onClick={handleCloseConfirmDialog}><CloseIcon /></IconButton>
                        </Box>
                        <Typography variant="modal_title" sx={{ textTransform: 'uppercase', px: 3 }} align="center">
                            Good luck and have a great zoom!
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, alignItems: 'center' }}>
                            <Button variant="contained"
                                sx={{
                                    px: 3, py: 2,
                                    border: '1px solid #000',
                                    backgroundColor: '#fff',
                                    color: '#000', height: '40px',
                                    '&:hover, &:hover>.MuiSvgIcon-root': {
                                        color: '#fff'
                                    }
                                }}
                                onClick={handleCloseModal}>
                                Yes
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleCloseConfirmDialog} sx={{ ml: 3, px: 3, py: 2 }}>No</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </LocalizationProvider>
    );
}

export default NewMeeting;