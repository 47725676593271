import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Typography, Button, Link, Stack, useMediaQuery } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import GroupIcon from '@mui/icons-material/Group';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import WKPanel from "@components/Base/WKPanel";
import { toWorkingLiveDate, toWorkingLiveTime, toLocalDateTime } from "@utils/transformers";

const PreviousMeetingPanel = (props) => {
    const ecwid = useSelector(state => state.ecwid);
    const { onShowAffiliates, meeting } = props;
    const meetingDateTime = new Date(meeting?.scheduled_datetime);
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const quantityOfMeeting = () => {
        let quantity = 0;
        for (let i = 0; i < meeting.orders.length; i ++) {
            const meetingOrder = meeting.orders[i];
            const order = ecwid?.orders.find(order => order.id == meetingOrder.order);
            quantity += order ? order?.totalQuantity : 0;
        }

        return quantity;
    }

    return (
        <WKPanel>
            <Grid container>
                <Grid item md={12} sx={{ borderBottom: '1px solid #EEEEEE', pb: 3, width: '100%' }}>
                    {
                        isOnMobile ?
                            <Stack>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2 }}>{ meeting.topic }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888' }}>Meeting ID: { meeting.zoom_id }</Typography>
                                </Box>
                                <Stack sx={{ mt: 2 }}>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, width: '100%' }}>
                                        <ShareIcon sx={{ color: '#000' }} />&ensp;Share
                                    </Button>
                                    <Button component={ RouterLink } variant="contained" sx={{ ...theme.custom.SubActionButton, mt: 2, width: '100%' }} to={`/sale/${ meeting.zoom_id }`}>
                                        <ShoppingCartIcon sx={{ color: '#000' }} />
                                        &ensp;View Sales
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, mt: 2, width: '100%' }}>
                                        <PlayArrowIcon sx={{ color: '#000' }} />&ensp;Play Recording
                                    </Button>
                                </Stack>
                            </Stack>
                        :
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2 }}>{ meeting.topic }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888' }}>Meeting ID: { meeting.zoom_id }</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton }}>
                                        <ShareIcon sx={{ color: '#000' }} />&ensp;Share
                                    </Button>
                                    <Button component={ RouterLink } variant="contained" sx={{ ...theme.custom.SubActionButton, ml: 2 }} to={`/sale/${ meeting.zoom_id }`}>
                                        <ShoppingCartIcon sx={{ color: '#000' }} />
                                        &ensp;View Sales
                                    </Button>
                                    <Button variant="contained" sx={{ ...theme.custom.SubActionButton, ml: 2 }}>
                                        <PlayArrowIcon sx={{ color: '#000' }} />&ensp;Play Recording
                                    </Button>
                                </Box>
                            </Box>
                    }
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, width: '100%' }}>
                    {
                        isOnMobile ?
                            <Stack sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2, letterSpacing: '2px' }}>{ toWorkingLiveDate(meetingDateTime) }</Typography>
                                    <Typography variant="h4" sx={{ letterSpacing: '2px' }}>{ toWorkingLiveTime(meetingDateTime) }</Typography>
                                </Box>
                                <Stack sx={{ display: 'flex', mt: 2, width: '100%' }}>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none' }}>
                                        <QueryBuilderIcon sx={{ color: '#000' }} />&ensp;45 min
                                    </Link>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none', mt: 2 }}>
                                        <GroupIcon />&ensp;{ meeting?.affiliates.length }
                                    </Link>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none', mt: 2 }}>
                                        <ShoppingCartIcon />&ensp;{ quantityOfMeeting() }
                                    </Link>
                                    <Typography variant="h5" color="primary" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', textTransform: 'none', mt: 2, width: '100%', justifyContent: 'flex-end' }} onClick={ onShowAffiliates }>Show Affiliates</Typography>
                                </Stack>
                            </Stack>
                        :
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ mb: 2, letterSpacing: '2px' }}>{ toWorkingLiveDate(meetingDateTime) }</Typography>
                                    <Typography variant="h4" sx={{ letterSpacing: '2px' }}>{ toWorkingLiveTime(meetingDateTime) }</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none' }}>
                                        <QueryBuilderIcon sx={{ color: '#000' }} />&ensp;45 min
                                    </Link>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none', ml: 3 }}>
                                        <GroupIcon />&ensp;{ meeting?.affiliates.length }
                                    </Link>
                                    <Link sx={{ color: 'rgba(0, 0, 0, 0.5)', textTransform: 'none', ml: 3 }}>
                                        <ShoppingCartIcon />&ensp;{ quantityOfMeeting() }
                                    </Link>
                                    <Typography variant="h5" color="primary" sx={{ display: 'flex', alignItems: 'center', ml: 3, cursor: 'pointer', textTransform: 'none' }} onClick={ onShowAffiliates }>Show Affiliates</Typography>
                                </Box>
                            </>
                    }
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
                    {
                        meeting.description
                            ?
                                <Typography variant="body1" sx={{ background: 'linear-gradient(#000, #fff)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                    { meeting.description }
                                </Typography>
                            :
                            <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>No description.</Typography>
                    }
                    {/**
                    <Typography variant="h5" color="primary" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3, cursor: 'pointer' }}><KeyboardArrowDownIcon />&ensp;Show Description</Typography>
                     */}
                </Grid>
            </Grid>
        </WKPanel>
    );
}

export default PreviousMeetingPanel;