import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, Stack, useMediaQuery } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { useTheme } from '@mui/material/styles';

import WKContentWrapper from "@components/Base/WKContentWrapper";
import InfoPanel from "@components/SellwithZoom/InfoPanel";
import ProductPanel from "@components/SellwithZoom/ProductPanel";
import { WKQuantityIcon, WKSalesIcon } from "@components/Base/WKIcons";
import { toWorkingLiveDate, toWorkingLiveTime } from "@utils/transformers";

const MeetingSale = () => {
    const meetings = useSelector(state => state.meeting);
    const ecwid = useSelector(state => state.ecwid);
    const { zoomID } = useParams();
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const goBack = () => {
        window.history.go(-1);
    }

    const saleInfo = { totalQuantity: 0, totalSale: 0 };
    let containingProducts = {};
    let meeting = meetings?.previous.find(m_meeting => m_meeting.zoom_id == zoomID);
    meeting = typeof meeting == 'object' ? JSON.parse(JSON.stringify(meeting)) : {};
    if (meeting && ecwid.orders.length) {
        meeting?.orders.map(order => {
            const order_id = order.order;
            order.order = ecwid.orders.find(order => order.id == order_id);
            saleInfo.totalQuantity += order.order.totalQuantity;
            saleInfo.totalSale += order.order.usdTotal;
        });
    }

    return (
        <WKContentWrapper>
            <Grid container sx={{ display: 'flex', flexDirection: 'column', pb: 5 }}>
                <Grid item md={12} sx={{ borderBottom: '1px solid #EEE' }}>
                    <Typography variant="h5" sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center', mb: 4, cursor: 'pointer' }} onClick={ goBack }>
                        <KeyboardArrowLeftIcon color="text" sx={{ mr: 2 }} />Back
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 3 }}>
                        <Typography variant="h5" sx={{ color: '#888888', mb: 2, textTransform: 'uppercase' }}>Completed</Typography>
                        <Typography variant="h3" sx={{ mb: 2 }}>{ meeting?.topic }</Typography>
                        <Typography variant="h5" sx={{ color: '#888888' }}>Meeting ID: { meeting?.zoom_id }</Typography>
                        {
                            isOnMobile ?                                
                                <Stack sx={{ mt: 3 }}>
                                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                        <CalendarTodayIcon sx={{ mr: '7px' }} />
                                        {
                                            meeting?.scheduled_datetime && toWorkingLiveDate(meeting?.scheduled_datetime)
                                        }
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 2, display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                        <QueryBuilderIcon sx={{ mr: '7px' }} />
                                        {
                                            meeting?.scheduled_datetime && toWorkingLiveTime(meeting?.scheduled_datetime)
                                        }
                                    </Typography>
                                </Stack>
                            :
                                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                        <CalendarTodayIcon sx={{ mr: '7px' }} />
                                        {
                                            meeting?.scheduled_datetime && toWorkingLiveDate(meeting?.scheduled_datetime)
                                        }
                                    </Typography>
                                    <Typography variant="h4" sx={{ ml: 4, display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                        <QueryBuilderIcon sx={{ mr: '7px' }} />
                                        {
                                            meeting?.scheduled_datetime && toWorkingLiveTime(meeting?.scheduled_datetime)
                                        }
                                    </Typography>
                                </Box>
                        }
                    </Box>
                </Grid>
                <Grid item md={12} sx={{ mt: 3, pb: 3, borderBottom: '1px solid #EEE' }}>
                    <Grid item md={12} sx={{ mb: 3 }}>
                        <Typography variant="h3">Total sales overview</Typography>
                    </Grid>
                        
                    <Grid container columnSpacing={2}>
                        <Grid item md={6} xs={12}>
                            <InfoPanel
                                icon={ <WKQuantityIcon/> }
                                title="Total Number of Products sold"
                                content={ saleInfo.totalQuantity }
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ mt: isOnMobile ? 2: 0 }}>
                            <InfoPanel
                                icon={ <WKSalesIcon /> }
                                title="Total Sales"
                                content={ `$${ saleInfo.totalSale }` }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} sx={{ mt: 3, pb: 3 }}>
                    <Grid item md={12} sx={{ mb: 3 }}>
                        <Typography variant="h3">Products</Typography>
                    </Grid>
                        
                    <Grid container rowSpacing={2}>
                        <Grid item md={12}>
                            {
                                !ecwid?.saleProducts.length
                                ?
                                    <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't sold any items yet.</Typography>
                                :
                                    ecwid?.saleProducts.map((product, index) =>
                                        <ProductPanel key={ product.id } product={ product } />
                                    )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            {/**
            <Link color="primary" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', '& svg': { color: theme.palette.primary.main } }}><KeyboardArrowDownIcon />&ensp;Load More</Link>
            */}
        </WKContentWrapper>
    );
}

export default MeetingSale;