export const WKQuantityIcon = () =>
    (<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_24_798)">
            <path d="M12.5003 29.3337C13.2367 29.3337 13.8337 28.7367 13.8337 28.0003C13.8337 27.2639 13.2367 26.667 12.5003 26.667C11.7639 26.667 11.167 27.2639 11.167 28.0003C11.167 28.7367 11.7639 29.3337 12.5003 29.3337Z" fill="white" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.1663 29.3337C27.9027 29.3337 28.4997 28.7367 28.4997 28.0003C28.4997 27.2639 27.9027 26.667 27.1663 26.667C26.43 26.667 25.833 27.2639 25.833 28.0003C25.833 28.7367 26.43 29.3337 27.1663 29.3337Z" fill="white" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.83301 1.33301H7.16634L10.7397 19.1863C10.8616 19.8002 11.1956 20.3516 11.6831 20.7441C12.1706 21.1365 12.7806 21.345 13.4063 21.333H26.3663C26.9921 21.345 27.6021 21.1365 28.0896 20.7441C28.5771 20.3516 28.9111 19.8002 29.033 19.1863L31.1663 7.99968H8.49967" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_24_798">
            <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>)

export const WKSalesIcon = () =>
    (<svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2.5" y="9.5" width="5" height="18" fill="white"/>
        <rect x="0.5" y="27" width="25" height="2" fill="white"/>
        <rect x="10.5" y="5.5" width="5" height="22" fill="white"/>
        <rect x="18.5" y="0.5" width="5" height="27" fill="white"/>
    </svg>)