import { useEffect } from 'react';
import { Navigate } from 'react-router';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useSnackbar } from 'notistack';

import WebLayout from "@layouts/web";
import SellwithZoom from "@pages/web/SellwithZoom";
import Company from "@pages/web/Company";
import Products from "@pages/web/Products";
import MyAccount from "@pages/web/MyAccount";
import ZoomCall from '@pages/web/ZoomCall';
import MeetingSale from "@pages/web/MeetingSale";
import SignIn from "@pages/auth/SignIn";
import SignUp from "@pages/auth/SignUp";
import { setAccount } from "@store/slices/auth.slice";
import { setProducts } from "@store/slices/ecwid.slice";
import { filterProducts } from "@services/ecwid.service";

const routes = [
  {
    auth: true,
    path: '/sell',
    element: <WebLayout><SellwithZoom /></WebLayout>
  }, {
    auth: true,
    path: '/company',
    element: <WebLayout><Company /></WebLayout>
  }, {
    auth: true,
    path: '/products',
    element: <WebLayout><Products /></WebLayout>
  }, {
    auth: true,
    path: '/myaccount',
    element: <WebLayout><MyAccount /></WebLayout>
  }, {
    auth: true,
    path: '/sale/:zoomID',
    element: <WebLayout><MeetingSale /></WebLayout>
  }, {
    auth: false,
    path: '/signin',
    element: <WebLayout sidemenu={ false }><SignIn /></WebLayout>
  }, {
    auth: false,
    path: '/signup',
    element: <WebLayout sidemenu={ false }><SignUp /></WebLayout>
  }, {
    auth: false,
    path: '/zoom/j/:id',
    element: <ZoomCall />
  }
];

const MainRoutes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  let isLoggined = !!auth.account;

  const authRedirect = element => {
    if (isLoggined)
      return element;
    return <Navigate to="/signin" />
  }

  useEffect(() => {
    filterProducts({})
      .then(products => {
        dispatch(setProducts({ products: products.items }));
      })
      .catch(err => {
        enqueueSnackbar("Fetching Error!", { variant: 'error' });
      })
  }, []);

  if (localStorage.getItem('jwtToken') && !isLoggined) {
    try {
      const decoded = jwt_decode(localStorage.getItem('jwtToken'));
      isLoggined = true;
      dispatch(setAccount(decoded));
      
      return null;
    } catch (err) {
      
    }
  }

  return (
    <Router>
      <Routes>
        <Route index element={ isLoggined ? <Navigate to="/myaccount" /> : <Navigate to="/signin" /> } />
        {
          routes.map((route, index) =>
            <Route key={ index } path={ route.path } exact element={ route.auth ? authRedirect(route.element) : route.element } />
          )
        }
      </Routes>
    </Router>
  )
};

export default MainRoutes;