import { Container, Box, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SideMenu from "./Sidemenu";

const Content = props => {
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container maxWidth='xl' sx={{ zIndex: 'tooltip', color: '#000', mb: 10 }}>
            {
                isOnMobile
                    ?
                        <Stack>
                            {
                                props.sidemenu && <SideMenu isOnMobile={ isOnMobile } />
                            }
                            { props.children }
                        </Stack>
                    :
                        <Box sx={{ display: 'flex' }}>
                            {
                                props.sidemenu && <SideMenu />
                            }
                            { props.children }
                        </Box>               
            }
        </Container>
    );
}

export default Content;