import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { toTimeStamp } from "@utils/transformers";

const ZoomRoom = (props) => {
  const [currentZoomMtg, setCurrentZoomMtg] = useState(null);
  let auth = useSelector(state => state.auth);
  const { meetingConfig, hostUserID } = props;
  const theme = useTheme();
  const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

  const startMeeting = (ZoomMtg, signature) => {
    document.getElementById('zmmtg-root').style.display = 'block'
    ZoomMtg.init({
        videoDrag: true,
        leaveUrl: process.env.REACT_APP_URL,
	      disableCORP: !window.crossOriginIsolated,
        success: (success) => {
            ZoomMtg.join({
              signature: signature,
              meetingNumber: meetingConfig.mn,
              userName: auth.account ? auth.account.username : 'Guest',
              sdkKey: 'A38tidVn2e1mftj19VfPGW1Rq32GY3j9xjqX',
              userEmail: meetingConfig.email,
              passWord: meetingConfig.passWord,
              tk: '',
              success: (success) => {
                const findZoomComponents = () => {
                  const videoShareLayout = document.getElementsByClassName('video-share-layout');
                  if (videoShareLayout.length) {
                    const joinDialog = document.getElementsByClassName('join-dialog');
                    if (joinDialog.length) {
                      joinDialog[0].innerHTML = "";
                    }
                    videoShareLayout[0].style.height = `${ videoShareLayout[0].clientHeight - 40 }px`;
                    const meetingApp = document.getElementsByClassName('meeting-app');
                    meetingApp[0].style.height = `${ meetingApp[0].clientHeight - 40 }px`;
                  } else {
                    setTimeout(findZoomComponents, 100);
                  }
                };
                findZoomComponents();
                let widthS = window.innerWidth;
                let endBtn = document.getElementById('divLeaveMeeting');
                  endBtn.className += 'divLeaveMeetingView';
                if(widthS < 900){
                  endBtn.style.display = 'flex';
                }
                let shm = document.getElementsByClassName('sidebarShowMenu')[0];
                shm.style.height = '68vh';
                let shmu = document.getElementsByClassName('sidebarShowMenuURL')[0];
                shmu.style.height = '68vh';
                let ecwidc = document.getElementsByClassName('ecwid');
                if(ecwidc.length > 0){
                  ecwidc[0].style.maxHeight = '60vh';
                }
              },
              error: (error) => {
                console.log("error");
                console.log(error);
              }
          })
        },
        error: (error) => {
          console.log(error);
        }
    });
  };

  useEffect(() => {
    // console.log('AUTH: ', auth);
    // console.log('auth account: ', auth.account);
    // console.log("zoommtg: ", toTimeStamp(new Date()));
    (async () => {
      try {
        const { ZoomMtg } = require('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.5.0/lib', '/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');
        setCurrentZoomMtg(ZoomMtg);
        fetch(process.env.REACT_APP_ZOOM_NODE_SERVER_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Cross-Origin-Embedder-Policy": "require-corp",
            "Cross-Origin-Opener-Policy": "same-origin"
          },
          body: JSON.stringify({
            meetingNumber: meetingConfig.mn,
            role: auth.account && (hostUserID == auth.account.user_id) ? 1 : 0
          })
        })
        .then(res => res.json())
        .then(response => {
          startMeeting(ZoomMtg, response.signature, meetingConfig)
        }).catch(error => {
            console.error(error)
        })
      } catch (err) {
        console.error(err);
      }
    })();
  }, [auth.account]);
  
  return (
    <section className="watch-live">
      {
        isOnMobile ?
            <div id="zmmtg-root"/>
        :
          <div id="zmmtg-root"/>
      }
    </section>
  );
}

export default ZoomRoom;