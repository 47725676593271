import { Height } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const LeaveMeeting = (props) => {
  let state_m = useSelector(state => state.meeting);
  // console.log('STATE', state_m);
  let auth = useSelector(state => state.auth);
  setTimeout(() => {
    let nameButton = auth.account ? 'End' : 'End';
    let btnLeave = document.getElementById('btnButtonLeave');
    btnLeave.innerText = nameButton;
  }, 500);
  
  const leave = () => {
    let moreButton = document.getElementById('moreButton');
    if(moreButton != null){
      moreButton.click();
      let leaveButtonOption = document.getElementsByClassName('more-button__leave-menu');
      if(leaveButtonOption.length > 0){
        setTimeout(() => {
          leaveButtonOption[0].click();
        }, 500);
      }else{
        let leaveButton = document.getElementsByClassName('zmu-btn--danger');
        if(leaveButton.length > 0){
          setTimeout(() => {
            leaveButton[0].click();
          }, 500);
        }
      }
    }else{
      alert('Meeting not Started');
    }
  }
  
  return (
    <div id="divLeaveMeeting">
      <div className="footer__leave-btn-container">
        <button aria-haspopup="true" aria-expanded="false" type="button" onClick={() => leave()} id="btnButtonLeave" className="zmu-btn footer__leave-btn ax-outline ellipsis zmu-btn--danger zmu-btn__outline--blue" aria-label="" tabindex="0">Loading...<span className="loading" style={{display: "none"}}></span></button>
      </div>
    </div>
  );
}

export default LeaveMeeting;