import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WKPanel from "@components/Base/WKPanel";

const ProductPanel = ({ product }) => {
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const fetchImg = async (path) => {
        const img = await fetch(path, { mode: 'cors', headers: { 'Access-Control-Allow-Origin': '*' } });
        console.log("img: ", img);
        return img;
    }

    return (
        <WKPanel sx={{ mt: 2 }}>
            <Grid container>
                {
                    isOnMobile ?
                        <>
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                                <img crossOrigin="anonymous" src={ fetchImg(product?.smallThumbnailUrl) } alt="product" width={80} height={80} style={{ borderRadius: '4px', border: '1px solid #E7E8EB' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '100%', alignItems: 'center' }}>
                                    <Typography variant="h4" sx={{ mb: 1, textAlign: 'center' }}>{ product?.name }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888', mb: 1 }}>Product code: { product?.productId }</Typography>
                                    <Typography variant="h3" sx={{ color: '#000' }}>${ Number(product?.price).toFixed(2) }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 2 }}>
                                <Typography variant="h4" sx={{ mb: 1, letterSpacing: '1px' }}>Total amount</Typography>
                                <Typography variant="h3" sx={{ fontSize: '20px', lineHeight: '24px', letterSpacing: '1px' }} color="text.primary">${ Number(product?.saleAmount).toFixed(2) }</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 2 }}>
                                <Typography variant="h4" sx={{ mb: 1, letterSpacing: '1px' }}>Total Number of sales</Typography>
                                <Typography variant="h3" sx={{ fontSize: '20px', lineHeight: '24px', letterSpacing: '1px' }} color="text.primary">{ product.quantity }</Typography>
                            </Grid>
                        </>
                    :
                        <>
                            <Grid item md={7} sx={{ display: 'flex' }}>
                                <Box>
                                    <img crossOrigin="anonymous" src={ product?.smallThumbnailUrl } alt="product" width={80} height={80} style={{ borderRadius: '4px', border: '1px solid #E7E8EB' }} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                    <Typography variant="h4" sx={{ mb: 1 }}>{ product?.name }</Typography>
                                    <Typography variant="h5" sx={{ color: '#888888', mb: 1 }}>Product code: { product?.productId }</Typography>
                                    <Typography variant="h3" sx={{ color: '#000' }}>${ Number(product?.price).toFixed(2) }</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right', justifyContent: 'center' }}>
                                <Typography variant="h4" sx={{ mb: 1, letterSpacing: '1px', textAlign: 'right' }}>Total amount</Typography>
                                <Typography variant="h3" sx={{ fontSize: '20px', lineHeight: '24px', letterSpacing: '1px', textAlign: 'right' }} color="text.primary">${ Number(product?.saleAmount).toFixed(2) }</Typography>
                            </Grid>
                            <Grid item md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right', justifyContent: 'center' }}>
                                <Typography variant="h4" sx={{ mb: 1, letterSpacing: '1px', textAlign: 'right' }}>Total Number of sales</Typography>
                                <Typography variant="h3" sx={{ fontSize: '20px', lineHeight: '24px', letterSpacing: '1px', textAlign: 'right' }} color="text.primary">{ product.quantity }</Typography>
                            </Grid>
                        </>
                }
            </Grid>
        </WKPanel>
    );
}

export default ProductPanel;