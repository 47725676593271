import { useDispatch } from 'react-redux';
import { Modal, Fade, Box, IconButton, Typography, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import WKButton from "@components/Base/WKButton";
import { deleteMeeting as deleteMeetingAPI } from "@services/zoom.service";
import { deleteMeeting } from "@store/slices/meeting.slice";

const DeleteConfirmDialog = props => {
    const { deleteMeetingID, onClose } = props;
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleDeleteMeeting = () => {
        if (deleteMeetingID === null) return;

        deleteMeetingAPI({
            meeting_id: deleteMeetingID
        })
        .then(response => {
            if (deleteMeetingID == response.deleted_id) {
                enqueueSnackbar("Deleted upcoming meeting successfully!", { variant: 'success' });
                dispatch(deleteMeeting({ deletedId: deleteMeetingID }));
            } else {
                throw "Delete failed!";
            }
        })
        .catch(err => {
            console.log(err);
            enqueueSnackbar("Delete failed!", { variant: 'error' });
        });

        onClose();
    }

    return (
        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
            open={ deleteMeetingID !== null } onClose={ onClose } closeAfterTransition
            BackdropComponent={ Backdrop } BackdropProps={{ timeout: 500 }}>
            <Fade in={ deleteMeetingID !== null }>
                <Box sx={ theme.custom.WKDialog }>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={ onClose }><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="modal_title" sx={{ textTransform: 'uppercase', px: 3 }} align="center">
                        Are you sure you want to delete this meeting?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, alignItems: 'center' }}>
                        <WKButton type='secondary' onClick={ onClose }>Cancel</WKButton>
                        <WKButton type='primary' onClick={ handleDeleteMeeting } sx={{ ml: 3 }}>Delete</WKButton>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default DeleteConfirmDialog;