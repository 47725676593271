import { Box, Typography } from '@mui/material';

import WKPanel from "@components/Base/WKPanel";

const InfoPanel = props => {
    const { icon, title, content } = props;

    return (
        <WKPanel>
            <Box sx={{ backgroundColor: '#A83560', display: 'inline-block', padding: '12px', borderRadius: '10px', lineHeight: '0' }}>
                { icon }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>{ title }</Typography>
                <Typography variant="h2" color="primary">{ content }</Typography>
            </Box>
        </WKPanel>
    )
}

export default InfoPanel;