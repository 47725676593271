import { Grid, Checkbox, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WKPanel from "@components/Base/WKPanel";

const ProductPanel = (props) => {
    const { name, imageUrl, price, edit, onChange, checked } = props;
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <WKPanel sx={{ my: 1, py: '3px', pl: 2, pr: 3, borderRadius: '10px' }}>
            <Grid container>
                {
                    isOnMobile ?
                    <>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            {
                                edit && <Checkbox checked={ checked } onChange={ onChange } />
                            }
                            <Typography variant="h5" align="right" color="text.secondary">${ Number(price).toFixed(2) }</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ imageUrl } alt="product" width={32} height={32} style={{ marginLeft: '5px', borderRadius: '2px', border: '1px solid #E7E8EB' }} />
                            <Typography sx={{ ml: 2 }} variant="body2">{ name }</Typography>
                        </Grid>
                    </>
                        :
                    <>
                        <Grid item md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                            {
                                edit && <Checkbox checked={ checked } onChange={ onChange } />
                            }
                            <img src={ imageUrl } alt="product" width={32} height={32} style={{ marginLeft: '5px', borderRadius: '2px', border: '1px solid #E7E8EB' }} />
                            <Typography sx={{ ml: 2 }} variant="body2">{ name }</Typography>
                        </Grid>
                        <Grid item md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <Typography variant="h5" align="right" color="text.secondary">${ Number(price).toFixed(2) }</Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </WKPanel>
    );
}

export default ProductPanel;