import { Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledButtonLink = styled(Link)({
    color: '#000',
    '&:hover': {
      color: '#B62561',
      transition: 'color 0.3s'
    }
});

const WKButtonLink = function (props) {
    return (
        <StyledButtonLink { ...props }>
            { props.children }
        </StyledButtonLink>
    );
};

export default WKButtonLink;