import { sendEcwidRequest } from "./api";

export const filterProducts = ({ keyword }) => {
    return sendEcwidRequest({
        url: `/v3/${ process.env.REACT_APP_ECWID_STORE_ID }/products`,
        method: 'GET',
        params: {
            keyword: keyword || ''
        }
    });
}

export const filterOrders = ({ ids }) => {
    return sendEcwidRequest({
        url: `/v3/${ process.env.REACT_APP_ECWID_STORE_ID }/orders`,
        method: 'GET',
        params: {
            ids
        }
    });
}