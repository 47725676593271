const Custom = () => ({
    boxShadow: '0 8px 20px rgb(0, 0, 0, 0.06)',
    SubActionButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f4f4f4',
        borderRadius: '30px',
        color: '#000',
        height: '40px',
        padding: '15px 16px 15px 16px',
        '&>.MuiSvgIcon-root, & svg': {
            width: '16px',
            height: '16px',
            opacity: '0.5',
            fill: "rgba(0,0,0,0.5)",
        },
        '&:hover, &:hover>.MuiSvgIcon-root, &:hover>svg': {
            color: '#fff',
            fill: "#fff"
        }
    },
    WKDialog: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column'
    }
});

export default Custom;