import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledPrimaryButton = styled(Button)({
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '16px',
    paddingBottom: '16px'
});

const StyledSecondaryButton = styled(Button)({
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '16px',
    paddingBottom: '16px',
    border: '1px solid #000',
    backgroundColor: '#fff',
    color: '#000',
    '&:hover, &:hover>.MuiSvgIcon-root': {
        color: '#fff'
    }
});

const WKButton = (props) => {
    const { type, children, ...rest } = props;
    if (type == 'primary')
        return <StyledPrimaryButton variant="contained" color="primary" { ...rest }>{ children }</StyledPrimaryButton>;
    
    return <StyledSecondaryButton variant="contained" { ...rest }>{ children }</StyledSecondaryButton>;
}

export default WKButton;