import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Dialog, DialogContent, DialogTitle, Typography, IconButton, Box, Grid, InputLabel, FormControl, useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';

import WKInput from "@components/Base/WKInput";
import AffiliatePanel from "@components/SellwithZoom/AffiliatePanel";
import { addAffiliateToMeeting as addAffiliateToMeetingAPI, deleteAffiliateFromMeeting as deleteAffiliateFromMeetingAPI } from "@services/zoom.service";
import { addAffiliateToMeeting, deleteAffiliateFromMeeting } from "@store/slices/meeting.slice";

const AddAffiliate = props => {
    const [userID, setUserID] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const meetings = useSelector(state => state.meeting);
    const { enqueueSnackbar } = useSnackbar();
    const { meetingID, onClose } = props;
    const dispatch = useDispatch();
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const validate = () => {
        if (!userID) {
            enqueueSnackbar("Please type distributor ID!", { variant: 'error' });

            return false;
        } else if (!userEmail) {
            enqueueSnackbar("Please type distributor Email!", { variant: 'error' });

            return false;
        }
        
        return true;
    }

    const handleAddAffiliate = () => {
        if (!validate()) return;

        addAffiliateToMeetingAPI({
            meeting_id: meetingID,
            userID,
            userEmail
        })
        .then(affiliate => {
            setIsOpenAlert(true);
            setTimeout(() => {
                setIsOpenAlert(false);
            }, 3000);
            dispatch(addAffiliateToMeeting({ meetingID: meetingID, affiliate }));
        })
        .catch(err => {
            setIsOpenAlert(false);
        });

        setUserID('');
        setUserEmail('');
    }

    const handleDeleteAffiliate = affiliateID => () => {
        deleteAffiliateFromMeetingAPI({
            affiliate_id: affiliateID
        })
        .then(response => {
            if (affiliateID == response.deleted_id) {
                enqueueSnackbar("Deleted affiliate successfully!", { variant: 'success' });
                dispatch(deleteAffiliateFromMeeting({ meetingID, deletedAffiliateID: affiliateID }));
            } else {
                throw "Delete failed!";
            }
        })
        .catch(err => {
            console.log(err);
            enqueueSnackbar("Delete failed!", { variant: 'error' });
        });
    }

    const meeting = meetings?.upcoming.find(m_meeting => m_meeting.id == meetingID);

    return (
        <>
            <Dialog fullWidth={ true } maxWidth='sm' open={ meetingID !== null } onClose={ onClose } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={onClose}><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="modal_title" sx={{ textTransform: 'uppercase', textAlign: 'center', px: 5 }}>
                        Add Affiliates to your meeting
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ width: '100%', p: 5 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mt: 3, width: '100%' }}>
                            <Typography align="center" sx={{ width: '100%' }}>
                                Affiliates will receive a unique link for your meeting that can be shared with their personal networks. This allows them to receive credit for sales that occur during the meeting!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3, width: '100%' }}>
                            <FormControl variant="standard" size="small" fullWidth={true}>
                                <InputLabel shrink htmlFor="meeting-topic">
                                    Distributor ID
                                </InputLabel>
                                <WKInput id="meeting-topic" size="small" value={ userID } onChange={ e => setUserID(e.target.value) } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3, width: '100%' }}>
                            <FormControl variant="standard" size="small" fullWidth={true}>
                                <InputLabel shrink htmlFor="meeting-topic">
                                    Distributor Email
                                </InputLabel>
                                <WKInput id="meeting-topic" size="small" value={ userEmail } onChange={ e => setUserEmail(e.target.value) } />
                            </FormControl>
                        </Grid>
                        {
                            isOpenAlert &&
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Box sx={{ m: 0, width: '100%', backgroundColor: '#F5F7FA', px: 9, py: 1 }}>
                                        <Typography variant="body1" align="center">
                                            Done! This distributor will receive an email with a unique link to your meeting.
                                        </Typography>
                                    </Box>
                                </Grid>
                        }
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, width: '100%' }}>
                            <Button variant="contained" color="primary" sx={Object.assign({ px: 3, py: 2 }, isOnMobile ? { width: '100%' } : {})} onClick={ handleAddAffiliate }>Add Affiliate</Button>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            {
                                meeting?.affiliates.map(affiliate =>
                                    <AffiliatePanel key={ affiliate.id } affiliate={ affiliate } onDelete={ handleDeleteAffiliate(affiliate.id)  } />
                                )
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AddAffiliate;