import { sendRequest } from "./api";

export const createRoom = ({ topic, start_time }) => {
    return sendRequest({
        url: '/zoom/createRoom/',
        method: 'POST',
        data: {
            topic, start_time
        }
    });
}

export const createMeeting = async (payload) => {
    return sendRequest({
        url: '/zoom/createMeeting/',
        method: 'POST',
        data: {
            ...payload
        }
    });
}

export const getMeetingDetailById = async ({ zoom_id }) => {
    return sendRequest({
        url: `/zoom/${ zoom_id }/detail`,
        method: 'GET',
    });
}

export const getMeetings = ({ host_user }) => {
    return sendRequest({
        url: '/zoom/all',
        method: 'GET',
        params: {
            host_user
        }
    });
}

export const addAffiliateToMeeting = ({ meeting_id, userID, userEmail }) => {
    return sendRequest({
        url: `/zoom/${ meeting_id }/addAffiliate/`,
        method: 'POST',
        data: {
            userID, userEmail
        }
    });
}

export const deleteAffiliateFromMeeting = ({ affiliate_id }) => {
    return sendRequest({
        url: `/zoom/affiliate/${ affiliate_id }/delete`,
        method: 'GET',
    });
}

export const addOrderToMeeting = ({ meeting_id, order_id }) => {
    return sendRequest({
        url: `/zoom/${ meeting_id }/addOrder/`,
        method: 'POST',
        data: {
            order_id
        }
    })
}

export const deleteMeeting = ({ meeting_id }) => {
    return sendRequest({
        url: `/zoom/${ meeting_id }/delete`,
        method: 'GET'
    });
}

export const statusMeeting = ({ meeting_id }) => {
    return sendRequest({
        url: `/zoom/${ meeting_id }/status`,
        method: 'GET'
    });
}
