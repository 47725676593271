import moment from 'moment';

export const staticAssetUrl = image => `${ process.env.REACT_APP_API_SERVER_URL }/static/${ image }`;

export const toLocalDateTime = _datetime => moment(_datetime).local();

export const toWorkingLiveDate = _date => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const date = new Date(_date);
    let day = '' + date.getDate(), month = months[date.getMonth()], year = date.getFullYear();
    if (day.length < 2) day = '0' + day;


    return `${ day } ${ month } ${ year }`;
}

export const toWorkingLiveTime = _time => {
    const date = new Date(_time);

    let hours = date.getHours(), minutes = '' + date.getMinutes(), moment = 'AM';
    const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });
    const timeZoneName = dtf.formatToParts(date).find((part) => part.type == 'timeZoneName').value;

    if (hours >= 12) {
        hours -= 12;
        moment = 'PM';
    }
    if (hours == 0) hours = 12;
    hours = '' + hours;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return `${ hours }:${ minutes } ${ moment } (${ Intl.DateTimeFormat().resolvedOptions().timeZone })`;
}

export const toFullZoomCallUrl = id => {
    return `${ window.location.host.startsWith('localhost') ? "http" : "https" }://${ window.location.host }/zoom/j/${ id }`;
}

export const toPostgreDateTimeFormat = datetime => {
    return moment(datetime).format("YYYY-MM-DD HH:mm");
}

export const toZoomDateTimeFormat = datetime => {
    return moment(datetime).utc().format("YYYY-MM-DDTHH:MM:SSZ");
}

export const toTimeStamp = datetime => {
    return Date.parse(datetime) / 1000;
}

export const toDateTime = (_date, _time) => {
    const _datetime = new Date(_time.getTime());
    _datetime.setFullYear(_date.getFullYear());
    _datetime.setMonth(_date.getMonth());
    _datetime.setDate(_date.getDate());

    return _datetime;
}