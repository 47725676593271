import { configureStore } from '@reduxjs/toolkit';

import authReducer from "@store/slices/auth.slice";
import meetingReducer from "@store/slices/meeting.slice";
import ecwidReducer from "@store/slices/ecwid.slice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        ecwid: ecwidReducer,
        meeting: meetingReducer
    }
});

export default store;