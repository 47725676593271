import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    upcoming: [],
    previous: []
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        addNewMeeting: (state, { payload: { meeting } }) => {
            let upcomingMeetings = [ ...state.upcoming ];
            upcomingMeetings.push(meeting);
            state.upcoming = upcomingMeetings;
        },
        setPreviousMeetings: (state, { payload: { meetings } }) => {
            state.previous = [ ...meetings ];
        },
        setUpcomingMeetings: (state, { payload: { meetings } }) => {
            state.upcoming = [ ...meetings ];
        },
        deleteMeeting: (state, { payload: { deletedId } }) => {
            let upcomingMeetings = [ ...state.upcoming ];
            const deletingIndex = upcomingMeetings.findIndex(meeting => meeting.id == deletedId);
            upcomingMeetings.splice(deletingIndex, 1);
            state.upcoming = upcomingMeetings;
        },
        addAffiliateToMeeting: (state, { payload: { meetingID, affiliate } }) => {
            let upcomingMeetings = [ ...state.upcoming ];
            const addingMeetingIndex = upcomingMeetings.findIndex(meeting => meeting.id == meetingID);
            upcomingMeetings[addingMeetingIndex]?.affiliates.push(affiliate);
            state.upcoming = upcomingMeetings;
        },
        addOrderToMeeting: (state, { payload: { meetingID, order } }) => {
            let upcomingMeetings = [ ...state.upcoming ];
            const addingMeetingIndex = upcomingMeetings.findIndex(meeting => meeting.id == meetingID);
            upcomingMeetings[addingMeetingIndex]?.orders.push(order);
            state.upcoming = upcomingMeetings;
        },
        deleteAffiliateFromMeeting: (state, { payload: { meetingID, deletedAffiliateID } }) => {
            let upcomingMeetings = [ ...state.upcoming ];
            const index = upcomingMeetings.findIndex(meeting => meeting.id == meetingID);
            const affiliates = upcomingMeetings[index]?.affiliates;
            const deletingAffiliatesIndex = affiliates.findIndex(affiliate => affiliate.id == deletedAffiliateID);
            affiliates.splice(deletingAffiliatesIndex, 1);
            state.upcoming = upcomingMeetings;
        }
    },
});

export const { addNewMeeting, setPreviousMeetings, setUpcomingMeetings, deleteMeeting, addAffiliateToMeeting, deleteAffiliateFromMeeting,
                addOrderToMeeting } = meetingSlice.actions;
export default meetingSlice.reducer;