import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

import WKLink from "@components/Base/WKLink";
import WKTextField from "@components/Base/WKTextField";
import WKContentWrapper from "@components/Base/WKContentWrapper";
import { WKQuantityIcon, WKSalesIcon } from "@components/Base/WKIcons";
import NewMeeting from "@components/SellwithZoom/NewMeeting/index";
import AddAffiliate from "@components/SellwithZoom/AddAffiliate";
import AffiliatesList from "@components/SellwithZoom/AffiliatesList";
import InfoPanel from "@components/SellwithZoom/InfoPanel";
import DeleteConfirmDialog from "@components/SellwithZoom/DeleteConfirmDialog";
import PreviousMeetingPanel from "@components/SellwithZoom/PreviousMeetingPanel";
import UpcomingMeetingPanel from "@components/SellwithZoom/UpcomingMeetingPanel";
import ProductPanel from "@components/SellwithZoom/ProductPanel";
import { filterOrders } from "@services/ecwid.service";
import { getMeetings } from "@services/zoom.service";
import { setValues } from "@store/slices/ecwid.slice";
import { setPreviousMeetings, setUpcomingMeetings } from "@store/slices/meeting.slice";

const SellwithZoom = () => {
  const [isNewMeetingOpened, setIsNewMeetingOpened] = useState(false);
  const [dateValue, setDateValue] = useState([null, null]);
  const [deletemeetingID, setDeletemeetingID] = useState(null);
  const [addAffiliatesMeetingID, setAddAffiliatesMeetingID] = useState(null);
  const [showAffiliatesMeetingID, setShowAffiliatesMeetingID] = useState(null);
  const meetings = useSelector(state => state.meeting);
  const auth = useSelector(state => state.auth);
  const ecwid = useSelector(state => state.ecwid);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isOnMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const handleNewMeeting = state => () => {
    setIsNewMeetingOpened(state);
  }

  const handleAddAffiliate = meetingID => () => {
    setAddAffiliatesMeetingID(meetingID);
  }

  const handleAffiliatesList = meetingID => () => {
    setShowAffiliatesMeetingID(meetingID);
  }

  const handleDeleteConfirmDialog = meetingID => () => {
    setDeletemeetingID(meetingID);
  }

  useEffect(() => {
    if (!auth.account) return;

    getMeetings({ host_user: auth.account.user_id })
      .then(meetings => {
        console.log("meetings ids: ", meetings);
        const order_ids = [];

        for (let i = 0; i < meetings?.previous.length; i ++) {
          const meeting = meetings.previous[i];
          meeting.zoom_info = JSON.parse(meeting.zoom_info);
          
          for (let j = 0; j < meeting?.orders.length; j ++)
            order_ids.push(meeting.orders[j].order);
        }
        
        for (let i = 0; i < meetings?.upcoming.length; i ++) {
          const meeting = meetings.upcoming[i];
          meeting.zoom_info = JSON.parse(meeting.zoom_info);
        }

        order_ids.sort();
        console.log(order_ids.join(','));
        filterOrders({ ids: order_ids.join(',') })
          .then(response => {
            const orders = response.items;
            const ordersInfo = { totalQuantity: 0, totalSale: 0 };
            const products = {};
            for (let i = 0; i < orders.length; i ++) {
              if (orders[i].paymentStatus !== "PAID")
                continue;
              
              const eachTotalQuantity = orders[i].items.reduce((total, item) => {
                if (products[item["productId"]])
                  products[item["productId"]].quantity += item.quantity;
                else products[item["productId"]] = item;
                products[item["productId"]].saleAmount = products[item["productId"]].price * products[item["productId"]].quantity;

                return total + Number(item.quantity);
              }, 0);

              ordersInfo.totalQuantity += eachTotalQuantity;
              ordersInfo.totalSale += orders[i].usdTotal;
              orders[i].totalQuantity = eachTotalQuantity;
            }

            const saleProducts = Object.values(products);
            saleProducts.sort((product1, product2) => product2.saleAmount - product1.saleAmount);

            console.log("orders: ", orders);
            console.log("products: ", saleProducts);

            dispatch(setValues({ orders, saleProducts, totalQuantity: ordersInfo.totalQuantity, totalSale: ordersInfo.totalSale }));
          })
          .catch(err => {});
        
        dispatch(setPreviousMeetings({ meetings: meetings.previous }));
        dispatch(setUpcomingMeetings({ meetings: meetings.upcoming }));
      })
      .catch(err => {});
  }, [auth.account]);

  return (
    <WKContentWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ borderBottom: '1px solid #EEEEEE', pb: 5 }}>
          <Grid container columnSpacing={2} sx={{ mb: 3 }}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: '100%' }}>
              {
                isOnMobile
                  ?
                    <Stack sx={{ width: '100%' }}>
                      <Typography variant="h3">Total sales overview</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2, width: '100%' }}>
                          <DatePicker
                            value={dateValue}
                            onChange={(newDateValue) => {
                              setDateValue(newDateValue);
                            }}
                            renderInput={(params) => <WKTextField {...params} size='small' />}
                          />
                          &ensp;-&ensp;
                          <DatePicker
                            value={dateValue}
                            onChange={(newDateValue) => {
                              setDateValue(newDateValue);
                            }}
                            renderInput={(params) => <WKTextField {...params} size='small' />}
                          />
                      </Box>
                    </Stack>
                  :
                    <>
                      <Typography variant="h3">Total sales overview</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <DatePicker
                            value={dateValue}
                            onChange={(newDateValue) => {
                              setDateValue(newDateValue);
                            }}
                            renderInput={(params) => <WKTextField {...params} size='small' />}
                          />
                          &ensp;-&ensp;
                          <DatePicker
                            value={dateValue}
                            onChange={(newDateValue) => {
                              setDateValue(newDateValue);
                            }}
                            renderInput={(params) => <WKTextField {...params} size='small' />}
                          />
                      </Box>
                      {/* <iframe src="https://demodashboard-57dbe.web.app/desktop-dashboard?email=vintagebaubleswithamy@gmail.com" name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe> */}
                    </>
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <InfoPanel
                icon={ <WKQuantityIcon/> }
                title="Total Number of Products sold"
                content={ ecwid.totalQuantity }
              />
            </Grid>
            <Grid item md={6} xs={12} sx={{ mt: isOnMobile ? 2: 0 }}>
              <InfoPanel
                icon={ <WKSalesIcon /> }
                title="Total Sales"
                content={ `$${ (ecwid.totalSale).toFixed(2) }` }
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={2}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              {
                isOnMobile ?
                  <Stack sx={{ width: '100%' }}>
                    <Typography variant="h3">Top Products</Typography>
                    <Box sx={{ width: '100%', mt: 2 }}>
                      <WKLink to="/products" color="primary" sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Typography variant="h5" sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>View All Products<KeyboardArrowRightIcon color="text" /></Typography>
                      </WKLink>
                    </Box>
                  </Stack>
                :
                  <>
                    <Typography variant="h3">Top Products</Typography>
                    <WKLink to="/products" color="primary">
                      <Typography variant="h5" sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>View All Products<KeyboardArrowRightIcon color="text" /></Typography>
                    </WKLink>
                  </>
              }
            </Grid>
            <Grid item md={12}>
              {
                !ecwid?.saleProducts.length
                  ?
                    <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't sold any items yet.</Typography>
                  :
                    ecwid?.saleProducts.map((product, index) =>
                      index < 3 && <ProductPanel key={ product.id } product={ product } />
                    )
              }
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderBottom: '1px solid #EEEEEE', py: 5 }}>
          <Grid container columnSpacing={2} sx={{ mb: 3 }}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: '100%' }}>
              {
                isOnMobile ?
                  <Stack sx={{ width: '100%' }}>
                    <Typography variant="h3">Upcoming meetings</Typography>
                    <Button variant="contained" color="primary" onClick={ handleNewMeeting(true) } sx={{ width: '100%', mt: 2 }}>Set up Meeting</Button>
                  </Stack>
                  :
                    <>
                      <Typography variant="h3">Upcoming meetings</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" color="primary" onClick={ handleNewMeeting(true) }>Set up Meeting</Button>
                      </Box>
                    </>
              }
            </Grid>
          </Grid>

          <Grid container rowSpacing={2}>
            {
              !meetings.upcoming.length
                ?
                  <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't had a meeting before.</Typography>
                : 
                  <div style={{ height: '600px', overflowY: 'scroll' }}>
                    {
                      meetings.upcoming.map(meeting =>
                        <Grid item md={12} key={ meeting.id } sx={{ width: '100%' }}>
                          <UpcomingMeetingPanel meeting={ meeting } onAddAffiliate={ handleAddAffiliate(meeting.id) } onDelete={ handleDeleteConfirmDialog(meeting.id) } />
                        </Grid>
                      )

                    }
                  </div>
            }
          </Grid>
        </Box>

        <Box sx={{ py: 5 }}>
          <Grid container columnSpacing={2} sx={{ mb: 3 }}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: '100%' }}>
              {
                isOnMobile ?
                  <Stack sx={{ width: '100%' }}>
                    <Typography variant="h3">Previous meeting</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', mt: 2 }}>
                      <DatePicker
                        value={dateValue}
                        onChange={(newDateValue) => {
                          setDateValue(newDateValue);
                        }}
                        renderInput={(params) => <WKTextField {...params} size='small' />}
                      />
                      &ensp;-&ensp;
                      <DatePicker
                        value={dateValue}
                        onChange={(newDateValue) => {
                          setDateValue(newDateValue);
                        }}
                        renderInput={(params) => <WKTextField {...params} size='small' />}
                      />
                    </Box>
                  </Stack>
                  :
                    <>
                      <Typography variant="h3">Previous meeting</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DatePicker
                          value={dateValue}
                          onChange={(newDateValue) => {
                            setDateValue(newDateValue);
                          }}
                          renderInput={(params) => <WKTextField {...params} size='small' />}
                        />
                        &ensp;-&ensp;
                        <DatePicker
                          value={dateValue}
                          onChange={(newDateValue) => {
                            setDateValue(newDateValue);
                          }}
                          renderInput={(params) => <WKTextField {...params} size='small' />}
                        />
                      </Box>
                    </>
              }
            </Grid>
          </Grid>

          <Grid container rowSpacing={2}>
            {
              !meetings.previous.length
                ?
                  <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't had a meeting before.</Typography>
                : 
                  <div style={{ height: '600px', overflowY: 'scroll' }}>
                    {
                      meetings.previous.map(meeting =>
                        <Grid item md={12} key={ meeting.id } sx={{ width: '100%' }}>
                          <PreviousMeetingPanel meeting={ meeting } onShowAffiliates={ handleAffiliatesList(meeting.id) } />
                        </Grid>
                      )
                    }
                  </div>
            }
          </Grid>
        </Box>
        
        {/* 
        <Link color="primary" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', '& svg': { color: theme.palette.primary.main } }}><KeyboardArrowDownIcon />&ensp;Load More</Link>
        */}

      </LocalizationProvider>

      <NewMeeting open={ isNewMeetingOpened } onClose={ handleNewMeeting(false) } />
      <AddAffiliate meetingID={ addAffiliatesMeetingID } onClose={ handleAddAffiliate(null) } />
      <AffiliatesList meetingID={ showAffiliatesMeetingID } onClose={ handleAffiliatesList(null) } />
      <DeleteConfirmDialog deleteMeetingID={ deletemeetingID } onClose={ handleDeleteConfirmDialog(null) } />
    </WKContentWrapper>
  )
}

export default SellwithZoom;