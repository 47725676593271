import { useSelector } from 'react-redux';
import {
    Button, Dialog, DialogContent, DialogTitle, Typography, IconButton, Box, Grid, Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import WKPanel from "@components/Base/WKPanel";
import AffiliatePanel from "@components/SellwithZoom/AffiliatePanel";

const AffiliatesList = props => {
    const meetings = useSelector(state => state.meeting);
    const { meetingID, onClose } = props;

    const meeting = meetings?.previous.find(m_meeting => m_meeting.id == meetingID);

    return (
        <>
            <Dialog fullWidth={ true } maxWidth='sm' open={ meetingID !== null } onClose={ onClose } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={onClose}><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="modal_title" sx={{ textTransform: 'uppercase', textAlign: 'center', px: 5 }}>
                        Affiliates List
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ width: '100%', p: 5 }}>
                    <Grid container>
                        <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
                            <Typography variant="h4" align="center" sx={{ fontWeight: 700, fontSize: '19px' }}>
                                { meeting?.topic }
                            </Typography>
                            <Typography variant="h5" align="center" sx={{ color: '#888888', mt: 3, fontWeight: 600 }}>Meeting ID: { meeting?.zoom_id }</Typography>
                        </Grid>
                        <Grid item md={12} sx={{ mt: 3 }}>
                            {
                                meeting?.affiliates.map(affiliate =>
                                    <AffiliatePanel key={ affiliate.id } affiliate={ affiliate } view={ true } />
                                )
                            }
                        </Grid>
                        <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
                            <Button variant="contained" component={ Link } color="primary" sx={{ px: 3, py: 2 }} href="/myaccount">Back To My Account</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AffiliatesList;