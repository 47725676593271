import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, FormControl, InputLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useTheme } from '@mui/material/styles';

import WKContentWrapper from "@components/Base/WKContentWrapper";
import WKInput from "@components/Base/WKInput";
import ProductPanel from "@components/SellwithZoom/ProductPanel";

const Products = () => {
    const ecwid = useSelector(state => state.ecwid);
    const theme = useTheme();

    const goBack = () => {
        window.history.go(-1);
    }

    return (
        <WKContentWrapper>
            <Box sx={{ pb: 5 }}>
                <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center', mb: 4, cursor: 'pointer' }} onClick={ goBack }>
                        <KeyboardArrowLeftIcon color="text" sx={{ mr: 2 }} />Back
                    </Typography>
                    <hr style={{ border: '1px solid #EEEEEE', width: '100%' }} />
                    <Typography variant="h3" sx={{ mt: 3 }}>All products</Typography>
                    <FormControl variant="standard" size="small" fullWidth={true} sx={{ mt: 3 }}>
                        <InputLabel shrink htmlFor="meeting-topic" sx={{ ...theme.typography.subtitle, fontWeight: 700 }}>
                            Search
                        </InputLabel>
                        <WKInput id="meeting-topic" size="small" />
                    </FormControl>
                </Grid>
                <Grid container rowSpacing={2}>
                    <Grid item md={12}>
                        {
                            !ecwid?.saleProducts.length
                            ?
                                <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't sold any items yet.</Typography>
                            :
                                ecwid?.saleProducts.map((product, index) =>
                                    <ProductPanel key={ product.id } product={ product } />
                                )
                        }
                    </Grid>
                </Grid>
            </Box>

            {/**
            <Link color="primary" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', '& svg': { color: theme.palette.primary.main } }}><KeyboardArrowDownIcon />&ensp;Load More</Link>
            */}
        </WKContentWrapper>
    )
}

export default Products;