import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

import store from "@store";
import { setAuthTokens, logout } from "@store/slices/auth.slice";

export const EcwidAPIServer = "https://app.ecwid.com/api";

export const BackendAPIServer = `${ process.env.REACT_APP_API_SERVER_URL }/api`;

//export const BackendAPIServer = `http://localhost:8000/api`;

let ecwidAxiosInstance = axios.create({
    baseURL: EcwidAPIServer,
    headers: {
        "Cross-Origin-Embedder-Policy": "require-corp",
        "Cross-Origin-Opener-Policy": "same-origin"
    }
});

ecwidAxiosInstance.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${ process.env.REACT_APP_ECWID_TOKEN }`;

    return config;
});

let axiosInstance = axios.create({
    baseURL: BackendAPIServer,
    /*headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
    }*/
});

axiosInstance.interceptors.request.use(async req => {
    const { token, refreshToken } = store.getState().auth;

    if (token === null && refreshToken === null) return req;

    const user = jwt_decode(token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    try {
        const response = await axios.post(`${ BackendAPIServer  }/auth/refresh/`, {
            refresh: refreshToken
        });
        const { access, refresh } = response.data;
        //req.response?.config.headers.Authorization = `Bearer ${ access }`;
        req.headers.Authorization = `Bearer ${ access }`;

        store.dispatch(setAuthTokens({ token: access, refreshToken: refresh }));

        return req;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            store.dispatch(logout());
        }
        
        return null;
    }
});

axiosInstance.interceptors.request.use(async (config) => {
    const { token } = store.getState().auth;

    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
});

axiosInstance.interceptors.response.use(res => {
    console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
    
    return Promise.resolve(res);
}, err => {
    console.debug('[Response]', err.config.baseURL + err.config.url, err.response.status, err.response.data);

    return Promise.reject(err);
})

export const setAuthToken = token => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
};

export const sendEcwidRequest = ({ url, method, params, data }) => {
    return new Promise((resolve, reject) => {
        ecwidAxiosInstance({
            method,
            url,
            params,
            data: data || {}
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            reject(err.response)
        });
    });
}

export const sendRequest = ({ url, method, params, data }) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            method,
            url,
            params,
            data: data || {}
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            reject(err.response)
        });
    });
}

export default axiosInstance;